import "../../assets/css/sidebar.css?v1.0";
import { globalImages, challenges, community } from "../../utils/portalImages";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePermissions } from "../../context/permissionsContext";
import {
  COURSE,
  PROMOTE,
  LEADERBOARD,
  ADMIN_COURSE,
  LEVEL_ADMIN,
  LEVEL_ADVISOR,
  LEVEL_GUEST,
} from "../../utils/userPermissions";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import SidenavSkeleton from "../../skeleton/SidenavSkeleton";

const Sidebar = (props) => {
  const { hasPermission } = usePermissions();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoader(false), 1000);
  }, []);

  const currentUser = useSelector((state) => getCurrentUser(state));
  const HAS_ACTIVE_SUBSCRIPTION = currentUser.has_active_subscription;
  const HAS_ACTIVE_TRIAL = currentUser.has_active_subscription;
  const IS_ONBOARDING_COMPLETED = currentUser.is_onboarding_completed;
  const FUNNEL_TYPE = ["master", "live_opportunity_call"].includes(
    currentUser.funneltype
  );

  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname.split("/");
  const firstPath = `/${currentPath[1]}`;

  if (window.innerWidth < 1200) {
    if (
      document.body.classList.contains("g-sidenav-hidden") &&
      document.body.classList.contains("g-sidenav-pinned")
    ) {
      document.body.classList.remove("g-sidenav-pinned");
    }
  }

  const pinStatus = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      const sidenavToggler = document.querySelector(".sidenav-toggler");
      sidenavToggler.classList.remove("active");
    }
  };

  const handleClick = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      setTimeout(function () {
        document.body.classList.remove("g-sidenav-show");
        document.body.classList.add("g-sidenav-hidden");
      }, 100);
    }
  };

  const handleCourseClick = (e) => {
    if (!IS_ONBOARDING_COMPLETED && !hasPermission(LEVEL_ADMIN)) {
      e.preventDefault();
      Swal.fire(
        "After you complete the questionnaire, on-boarding process, and book your kick off call you will gain access to the courses."
      );
    } else if (!HAS_ACTIVE_SUBSCRIPTION && !hasPermission(LEVEL_ADMIN)) {
      e.preventDefault();
      navigate("/account/payment");
    }
  };

  const handleExpireSubscriptions = (e) => {
    if (
      !hasPermission(LEVEL_ADMIN) &&
      !HAS_ACTIVE_SUBSCRIPTION &&
      !hasPermission(LEVEL_GUEST)
    ) {
      e.preventDefault();
      navigate("/account/payment");
    }
  };

  return (
    <nav
      className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-default2 pb-0"
      id="sidenav-main"
      onMouseEnter={pinStatus}
    >
      <div className="scrollbar-inner bg-translucent-default">
        <div className="sidenav-header  d-flex  align-items-center">
          <Link className="pl-3 pt-1" to="/dashboard">
            <img
              src={globalImages.sidebar_logo}
              alt="logo"
              className=""
              style={{ height: "38px" }}
            />
          </Link>
          <div className="ml-auto">
            <div
              className="sidenav-toggler d-none d-xl-block active"
              data-action="sidenav-unpin"
              data-target="#sidenav-main"
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line bg-white" />
                <i className="sidenav-toggler-line bg-white" />
                <i className="sidenav-toggler-line bg-white" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <div className="sidenav-container">
            <div
              className={`skeleton ${
                loader ? "show-skeleton" : "hide-skeleton"
              }`}
            >
              <SidenavSkeleton />
            </div>
            <div
              className={`sidenav ${loader ? "hide-sidenav" : "show-sidenav"}`}
            >
              <div
                className="collapse navbar-collapse"
                id="sidenav-collapse-main"
                onClick={handleClick}
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      to="/dashboard"
                      className={`nav-link + ${
                        firstPath === "/dashboard" ? "active" : ""
                      }`}
                    >
                      <i
                        className="fa fa-home text-info"
                        style={{ fontSize: "18px" }}
                      />
                      <span className="nav-link-text d-block">Dashboard</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to={
                        HAS_ACTIVE_SUBSCRIPTION ||
                        hasPermission(LEVEL_ADMIN) ||
                        hasPermission(LEVEL_GUEST)
                          ? "/courses"
                          : "/account/payment"
                      }
                      className={`nav-link  + ${
                        firstPath === "/courses" ? "active" : ""
                      }`}
                    >
                      <i className="ni ni-single-copy-04 text-pink d-block" />
                      <span className="nav-link-text d-block">Courses</span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/calendar"
                      className={`nav-link + ${
                        firstPath === "/calendar" ? "active" : ""
                      }`}
                    >
                      <i className="ni ni-calendar-grid-58 text-green" />
                      <span className="nav-link-text d-block">Calendar</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={
                        HAS_ACTIVE_SUBSCRIPTION ||
                        hasPermission(LEVEL_ADMIN) ||
                        hasPermission(LEVEL_GUEST)
                          ? "/community"
                          : "/account/payment"
                      }
                      className={`nav-link  + ${
                        firstPath === "/community" ? "active" : ""
                      }`}
                    >
                      <img
                        className="pr-2"
                        src={community.communityLogo}
                        alt=""
                        height={28}
                        width={28}
                      />
                      <span className="nav-link-text d-block">Community</span>
                    </Link>
                  </li>

                  {hasPermission(PROMOTE) && (
                    <li className="nav-item">
                      <Link
                        to="/promote"
                        className={`nav-link  + ${
                          firstPath === "/promote" ? "active" : ""
                        }`}
                      >
                        <i className="ni ni-send text-orange" />
                        <span className="nav-link-text d-block">Promote</span>
                      </Link>
                    </li>
                  )}

                  {!hasPermission(LEVEL_ADMIN) && (
                    <li className="nav-item">
                      <Link
                        to={
                          HAS_ACTIVE_TRIAL ||
                          HAS_ACTIVE_SUBSCRIPTION ||
                          hasPermission(LEVEL_GUEST)
                            ? "/user-challenges"
                            : "/account/payment"
                        }
                        className={`nav-link  + ${
                          firstPath === "/user-challenges" ? "active" : ""
                        }`}
                      >
                        <img
                          src={challenges.crown}
                          alt="Gold Medal"
                          style={{ width: "18px", marginRight: "12px" }}
                        />
                        <span className="nav-link-text d-block">
                          Challenges
                        </span>
                      </Link>
                    </li>
                  )}

                  {hasPermission(LEVEL_ADVISOR) && (
                    <li className="nav-item">
                      <Link
                        to="/advisor-questionnaire"
                        className={`nav-link  + ${
                          firstPath === "/advisor-questionnaire" ? "active" : ""
                        }`}
                        onClick={handleExpireSubscriptions}
                      >
                        <i className="fa fa-question text-success" />
                        <span className="nav-link-text d-block">
                          Advisor Questionnaire
                        </span>
                      </Link>
                    </li>
                  )}
                  {hasPermission(LEADERBOARD) && (
                    <li className="nav-item">
                      <Link
                        to="/leaderboard"
                        className={`nav-link  + ${
                          firstPath === "/leaderboard" ? "active" : ""
                        }`}
                      >
                        <i className="ni ni-trophy text-info" />
                        <span className="nav-link-text d-block">
                          Leaderboard
                        </span>
                      </Link>
                    </li>
                  )}

                  <li className="nav-item">
                    <Link
                      to="/support"
                      className={`nav-link  + ${
                        firstPath === "/support" ? "active" : ""
                      }`}
                    >
                      <i className="ni ni-time-alarm text-red" />
                      <span className="nav-link-text d-block">Support</span>
                    </Link>
                  </li>
                </ul>

                <hr className="my-3 bg-gray" />
                <h6 className="navbar-heading p-0 text-muted">
                  <span className="docs-normal">Admin</span>
                  <span className="docs-mini">A</span>
                </h6>
                <ul className="navbar-nav mb-md-3">
                  {hasPermission(ADMIN_COURSE) && (
                    <>
                      <li className="nav-item">
                        <Link
                          to="/admin/courses"
                          className={`nav-link  + ${
                            firstPath === "/admin" ? "active" : ""
                          }`}
                        >
                          <i className="ni ni-app text-gray" />
                          <span className="nav-link-text d-block">
                            Courses Admin
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/guest-user/pending-request"
                          className={`nav-link  + ${
                            firstPath === "/guest-user" ? "active" : ""
                          }`}
                        >
                          <i className="fa fa-user-plus text-gray"></i>
                          <span className="nav-link-text d-block">
                            Guest User Requests
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/challenges"
                          className={`nav-link  + ${
                            firstPath === "/challenges" ? "active" : ""
                          }`}
                        >
                          <img
                            src={challenges.crownGray}
                            alt="Gold Medal"
                            style={{
                              width: "18px",
                              marginRight: "12px",
                              opacity: "0.7",
                            }}
                          />
                          <span className="nav-link-text d-block">
                            Admin Challenges
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/create-notifications"
                          className={`nav-link  + ${
                            firstPath === "/create-notifications"
                              ? "active"
                              : ""
                          }`}
                        >
                          <i className="fa fa-bell text-gray" />
                          <span className="nav-link-text d-block">
                            Notifications
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/quick-links"
                          className={`nav-link  + ${
                            firstPath === "/quick-links" ? "active" : ""
                          }`}
                        >
                          <i className="fa fa-link text-gray" />
                          <span className="nav-link-text d-block">
                            Quick Links
                          </span>
                        </Link>
                      </li>
                    </>
                  )}
                  <li className="nav-item">
                    <Link
                      to="/account"
                      className={`nav-link  + ${
                        firstPath === "/account" ? "active" : ""
                      }`}
                    >
                      <i className="ni ni-single-02 text-gray"></i>
                      <span className="nav-link-text d-block">Account</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={props.handleLogout}
                      className={`nav-link  + ${
                        firstPath === "/logout" ? "active" : ""
                      }`}
                    >
                      <i className="ni ni-user-run text-gray" />
                      <span className="nav-link-text d-block">Logout</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
