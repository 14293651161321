let globalImages = {
  sidebar_logo: require("../assets/images/r2f_logo.png"),
  advisor_bg: require("../assets/images/advisor_bg.png"),
  download: require("../assets/images/icons/common/download.svg").default,
};

let dashboard = {
  piggy_bank: require("../assets/images/piggy-bank.png"),
  check_icon: require("../assets/images/Vector-2.svg").default,
};

let funnelImages = {
  funnel_optin: require("../assets/images/funnel-optin.png"),
  funnel_checkout: require("../assets/images/funnel-checkout.png"),
  funnel_webinar: require("../assets/images/funnel-webinar.png"),
  funnel_thankyou: require("../assets/images/funnel-thankyou.png"),
};

let coursePageImages = {
  lock_icon: require("../assets/images/Group-40.jpg"),
  course_icon: require("../assets/images/Group-704.svg").default,
};

let errorPageImages = {
  error_404: require("../assets/images/not-found.svg").default,
  error_500: require("../assets/images/server-error.png"),
  error_403: require("../assets/images/error-403.png"),
  network_error: require("../assets/images/no-internet-error.png"),
};

let promoteImages = {
  intro_background: require("../assets/images/theme/02_RTF_Bundle.png"),
  dummy: require("../assets/images/theme/img-1-1000x900.jpg"),
};

let lessonImages = {
  arrow_down: require("../assets/images/arrow-down.png"),
};

let accountImages = {
  pingbank: require("../assets/images/piggy-bank.png"),
};

let adminCourseImages = {
  sortMenuDark: require("../assets/images/sort-menu-dark.png"),
  sortMenuLight: require("../assets/images/sort-menu-light.png"),
};

let challenges = {
  paperClip: require("../assets/images/icons/common/paperclip.svg").default,
  download: require("../assets/images/icons/common/download.svg").default,
  pause: require("../assets/images/icons/common/pause.svg").default,
  play: require("../assets/images/icons/common/play.svg").default,
  goldMedal: require("../assets/images/icons/common/gold-medal.svg").default,
  silverMedal: require("../assets/images/icons/common/silver-medal.svg")
    .default,
  bronzeMedal: require("../assets/images/icons/common/bronze-medal.svg")
    .default,
  crown: require("../assets/images/icons/common/crown.svg").default,
  crownGray: require("../assets/images/icons/common/crown-gray.svg").default,
};

let community = {
  communityLogo: require("../assets/images/icons/common/community.svg").default,
  boomark: require("../assets/images/icons/common/boomark.svg").default,
  boomarkFilled: require("../assets/images/icons/common/boomark-filled.svg")
    .default,
  thumbsUp: require("../assets/images/icons/common/thumbs-up.svg").default,
  imageBlack: require("../assets/images/icons/common/image-black.svg").default,
  imageGray: require("../assets/images/icons/common/image-gray.svg").default,
  videoBlack: require("../assets/images/icons/common/video-black.svg").default,
  videoGray: require("../assets/images/icons/common/video-gray.svg").default,
  gifBlack: require("../assets/images/icons/common/gif-black.svg").default,
  gifGray: require("../assets/images/icons/common/gif-gray.svg").default,
  activityBlack: require("../assets/images/icons/common/activity-black.svg")
    .default,
  activityGray: require("../assets/images/icons/common/activity-gray.svg")
    .default,
  pollBlack: require("../assets/images/icons/common/poll-black.svg").default,
  pollGray: require("../assets/images/icons/common/poll-gray.svg").default,
  fileBlack: require("../assets/images/icons/common/file-black.svg").default,
  fileGray: require("../assets/images/icons/common/file-gray.svg").default,
};

let emojis = {
  like: require("../assets/images/icons/common/like.svg").default,
  love: require("../assets/images/icons/common/love.svg").default,
  haha: require("../assets/images/icons/common/haha.svg").default,
  wow: require("../assets/images/icons/common/wow.svg").default,
  sad: require("../assets/images/icons/common/sad.svg").default,
  angry: require("../assets/images/icons/common/angry.svg").default,
};

let activity = {
  feeling: require("../assets/images/icons/common/feeling.svg").default,
  traveling: require("../assets/images/icons/common/traveling.svg").default,
  watching: require("../assets/images/icons/common/watching.svg").default,
  playing: require("../assets/images/icons/common/playing.svg").default,
  listening: require("../assets/images/icons/common/listening.svg").default,
};

let notification = {
  checkSquare: require("../assets/images/icons/common/check-square.svg")
    .default,
};

export {
  globalImages,
  dashboard,
  funnelImages,
  coursePageImages,
  errorPageImages,
  promoteImages,
  lessonImages,
  accountImages,
  adminCourseImages,
  challenges,
  community,
  emojis,
  activity,
  notification,
};
