import axios from "axios";

const UpdatePostNotificationData = {
  post: null,
  comment: null,
  reaction: null,
};

export async function createUserNotifications() {
  return await axios
    .post("/admin-notifications/create")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}

export async function fetchAdminNotifications() {
  return await axios
    .get("/admin-notifications/filtered")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}

export async function fetchAllNotifications() {
  return await axios
    .get("/notifications/")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}

export async function fetchUserNotifications(uuid) {
  return await axios
    .get(`/notifications/${uuid}/single`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}

export async function updateNotificationReadStatus(uuid) {
  return await axios
    .post(`/notifications/${uuid}/update-read-status`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}

export async function updateAllNotificationReadStatus() {
  return await axios
    .post(`/notifications/mark-all-as-read`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}

export async function updatePostSettings(payload = UpdatePostNotificationData) {
  return await axios
    .post("/notifications/settings/store", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function getPostSettings() {
  return await axios
    .get("/notifications/settings")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}
